import './App.css';
import Routes from './routes/routes'
import "../src/assets/css/icon.css"
import "../src/assets/css/font-awesome.min.css"
// import Modal from 'react-modal';
// Modal.setAppElement('#root')

function App() {
  return (
    <Routes />
    
  );
}

export default App;
